<template>
  <div class="flex flex-column searchr">
    <div class="searchr-top">
      <div class="flex flex-between align-center searchr-top-search">
        <div class="flex flex-left align-center searchr-top-search-l">
          <img class="searchr-top-search-l-img" src="../../../assets/img/home/search.png" alt="">
          <input class="searchr-top-search-l-input" type="text" v-model="searchName" @click="changeSearch" placeholder="热门" readonly>
        </div>
        <div class="searchr-top-search-service" @click="openServiceFunc"></div>
      </div>
      <div class="flex flex-between searchr-top-sx">
        <div class="flex flex-left align-center searchr-top-sx-l">
          <div class="flex flex-center align-center searchr-top-sx-l-list" v-for="(item,index) in tagList" :key="item.id" :class="tagIdx == index ? 'searchr-top-sx-l-list1' : ''" @click="sortFunc(index)">{{item.name}}
            <img class="searchr-top-sx-l-list-img" v-if="index == 1" :src="tagIdx == 1 ? (item.checked ? require('../../../assets/img/search/sx-down.png') : require('../../../assets/img/search/sx-up.png')) : require('../../../assets/img/search/shaixuan.png')" />
            <i class="searchr-top-sx-l-list-i" v-if="tagIdx == index"></i>
          </div>
          <!-- <div class="flex flex-center align-center searchr-top-sx-l-list" :class="sortMethod == 5 ? 'searchr-top-sx-l-list1' : ''">奖励积分
            <img class="searchr-top-sx-l-list-img" src="../../../assets/img/search/shaixuan.png"/>
            <i class="searchr-top-sx-l-list-i" v-if="sortMethod == 5"></i>
          </div> -->
        </div>
        <div class="flex flex-right align-center searchr-top-sx-r" @click="gotoPagesFunc('xs')">
          <img src="../../../assets/img/search/sx.png" alt="">筛选
        </div>
      </div>
    </div>
    <!-- 商品列表 -->
    <div class="searchr-result" v-if="goodsList.length > 0">
      <van-list
          v-model:loading="loading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="false"
          @load="searchResultFunc"
        >
        <div class="flex flex-left searchr-result-list" v-for="item in goodsList" :key="item.id" @click="goGoodsDetailFunc(item.goodId)">
          <div class="searchr-result-list-l" :style="{background: 'url('+ item.goodPic +')no-repeat center center', backgroundSize: 'cover'}">
            <!-- <img :src="item.goodPic" alt=""> -->
            <div class="flex flex-center align-center searchr-result-list-l-tag" v-if="item.tag">{{item.tag}}</div>
          </div>
          <div class="searchr-result-list-r">
            <div class="searchr-result-list-r-name">
              {{item.goodName}}
            </div>
            <div class="searchr-result-list-r-brand">品牌：{{item.brandName}}</div>
            <div class="flex flex-left searchr-result-list-r-yh" v-if="item.coupon || item.score">
              <span class="" v-if="item.coupon">{{item.coupon.name}}</span>
              <span class="" v-if="item.score">得{{item.score}}龙珠</span>
            </div>
            <div class="flex flex-between align-center searchr-result-list-r-price">
              <div class="searchr-result-list-r-price-l">
                ¥<span class="searchr-result-list-r-price-l-num">{{parseFloat(item.salePrice/100).toFixed(2)}}</span>起
                <span class="searchr-result-list-r-price-l-toast">灵龙价</span>
              </div>
              <!-- <div class="searchr-result-list-r-price-sale">销量{{item.saleNum}}</div> -->
              <div class="searchr-result-list-r-price-sale" style="text-decoration: line-through;color:#999;">原价¥{{parseFloat(item.goodsOriginalPrice/100).toFixed(2)}}</div>
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <!-- 没有数据 -->
    <div class="searchr-result searchr-result1" v-if="goodsList.length == 0">
      <div class="searchr-result-nodata">
        <div class="searchr-result-nodata-img"></div>
        <div class="searchr-result-nodata-msg">
          <p class="searchr-result-nodata-msg-p">没有找到匹配的结果</p>
          <p class="searchr-result-nodata-msg-p1">建议您修改关键词重新搜索</p>
        </div>
      </div>
    </div>

    <!-- 客服 -->
    <service-popup></service-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>