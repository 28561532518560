import {
    getHomeGoods
} from '@/utils/home.js';
import servicePopup from '@/components/service.vue';
import { provide, ref } from 'vue';
export default {
    setup() {
        let showGroup = ref(false);
        provide('showgroup', showGroup);
        return {
            showGroup
        }
    },
    components: {
        servicePopup
    },
    data() {
        return {
            goodsList: [],
            page: 1,
            oldPage: 0,
            loading: false,
            finished: false,
            searchType: '',
            searchName: '',
            sortMethod: 1,
            tagIdx: 0,
            tagList: [{
                    name: '默认',
                    checked: false,
                },
                {
                    name: '价格',
                    checked: false,
                },
                {
                    name: '销量',
                    checked: false,
                }
            ]
        }
    },
    created() {
        this.searchType = this.$route.query.searchType;
        this.searchName = this.$route.query.searchName;
        sessionStorage.setItem('searchName', this.searchName);
        this.searchResultFunc();
    },
    methods: {
        searchResultFunc() {
            if (this.oldPage == this.page) return false;
            this.oldPage = this.page;
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: this.searchName,
                sortMethod: this.sortMethod, // 1=默认，2=按销量排序，3=按价格从地到高，4=按价格从高到低
                pageNo: this.page,
                pageSize: 10,
                channel: 0, //(0=普通专区,1=限时积分专区,2=达人专区)
                categoryFirstId: '',
                tag: '',
                searchType: this.searchType
            }
            getHomeGoods(data).then(res => {
                console.log('获取商品', res.result)
                if (res.result) {
                    let arr = res.result.goodList;
                    arr.forEach(item => {
                            this.goodsList.push(item)
                        })
                        // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (arr.length < 10) {
                        this.finished = true;
                    } else {
                        this.page++;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
        // 排序
        sortFunc(idx) {
            this.tagIdx = idx;
            switch (idx) {
                case 0:
                    this.sortMethod = 1;
                    this.tagList[1].checked = false;
                    break;
                case 1:
                    this.sortMethod = this.tagList[idx].checked ? 3 : 4;
                    this.tagList[idx].checked = !this.tagList[idx].checked;
                    break;
                case 2:
                    this.sortMethod = 2;
                    this.tagList[1].checked = false;
                    break;
            }

            this.goodsList = [];
            this.page = 1;
            this.oldPage = 0;
            this.loading = false;
            this.finished = false;
            this.searchResultFunc();
        },
        // 跳转到搜索页面
        gotoPagesFunc() {
            this.$router.push({
                path: '/categary'
            })
        },
        // 搜索
        changeSearch() {
            this.$router.push({
                path: '/search',
            })
        },
        // 跳转到商品详情
        goGoodsDetailFunc(id) {
            this.$router.push({
                path: '/goodsDetail',
                query: {
                    goodsId: id
                }
            })
        },
        // 打开客服
        openServiceFunc() {
            this.showGroup = true
        }
    },
}